import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { Experience } from './components/Experience/Experience';
import { Resume } from './components/Resume/Resume';
import { Home } from './components/Home/Home';
import { Nav } from './shared/components/Nav/Nav';
import { About } from './components/About/About';

function App() {
  return (
    <main className="app">
      <BrowserRouter>
        <Nav />
        <Switch>
          <Route component={Home} path="/home" />
          <Route component={Experience} path="/experience" />
          <Route component={Resume} path="/resume" />
          <Route component={About} path="/about" />
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </BrowserRouter>
    </main>
  );
}

export default App;
