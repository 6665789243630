import './Home.scss';

export const Home = () => {
  return (
    <article className="home__container">
      <section className="home__back"></section>
      <section className="home__body">
        <div className="home__body-text">
          Hi, my name is <br className="home__body-name-break"/><b>Kanu Priya Middha</b>. <br /><span className="home__body-text--sub">I am a web developer and a design enthusiast.</span>
        </div>
      </section>
    </article>
  );
};
