import './About.scss';
export const About = () => {
  return (
    <article className="about__container">
      <section className="about__body">
        <div className="about__header">
          <div className="about__image"></div>
          <p>Hey There! I am Kanu Priya. <br />I am a web developer and a problem solver at heart.</p>
        </div>
        <div className="about__text">
          <p>I'm driven by:</p>
          <ul>
            <li>Employing engineering best practices to establish maintainable, reliable, and secure software</li>
            <li>Attention to detail, especially around performance, accessibility, reliability and security</li>
            <li>A combined passion for great user experience and API design</li>
            <li>An ability to learn quickly from peers, projects and interactions with customers</li>
            <li>A culture of continuous improvement, adaptation, reflection and growth</li>
            <li>Empathy, a collaborative spirit, mentorship and the ability to work effectively in a team</li>
            <li>Strong organization skills, a bias for action, and ability to deliver results</li>
          </ul>
          <p>Some experience I've gained:</p>
          <ul>
            <li>6+ years as a Web Developer with a Customer Centric mindset</li>
            <li>Large scale web applications development and shipping high-quality products from start to finish.</li>
            <li>Proficient in JavaScript, Typescript and the modern frameworks - Angular and React</li>
            <li>Adept at responsive styling using CSS, Sass, Flexbox</li>
            <li>Creating reusable in-house libraries using Angular for development across multiple brand products</li>
            <li>Backend Development using Node.js, SQL</li>
            <li>Strong coding, debugging and problem solving skills by employing Data Structures and Algorithms</li>
            <li>Reviewing web application designs and liaise with designers to decide on UX elements</li>
            <li>Test Driven Development and Unit Testing frameworks - Jasmine</li>
            <li>Creating optimized web solutions using State-management frameworks - NgRx, Akita, Redux</li>
            <li>Documenting and reviewing High-Level and Detailed Technical Designs and overall Systems Design</li>
            <li>Driving end-to-end features - Design, Development, Integration, Reviews, Testing, and Deployment</li>
            <li>Domains worked in - Telecom Business Products, Data Dashboards, HealthCare, E-Commerce</li>
          </ul>

          Feel free to contact me via <a href="mailto:kanupriya.middha@gmail.com">Email</a> or <a href="https://www.linkedin.com/in/kpmiddha">LinkedIn</a>
        </div>
      </section>
    </article>
  );
};
