import { Project } from "../shared/models/project"

export const Projects: Project[] = [{
  name: 'Voucher Management',
  role: 'Senior Software Engineer - Front End',
  description: [
    'Developed core features for the product.',
    'Created Abstraction Layers for various components such as Tables, Detail Pages, Forms to make the development scalable, reusable and non-repetitive.',
    'Exported the project as a Library to be used in different external products.',
    'Contributed reusable and extendable components to the in house UX library.',
  ],
  stack: 'Angular 11, TypeScript, HTML, SCSS, Akita'
}, {
  name: 'Life Sciences and Health Care E-Commerce Site',
  role: 'Front End Web Developer and Lead',
  description: [
    'Lead a Front End Team of 10 to deliver this E-Commerce site with a Headless Architecture comprising Angular, Node.js & Elastic Path.',
    'Made sure the story deliverables were kept hand-in-hand with Code Quality, Security, Performance & maintained more than 80% Logical Unit Test Coverage at all times.',
    'In the Discovery Phase, underwent Elastic Path backend trainings to understand the new Commerce Engine’s compatibility with client’s needs.',
    'In the Dev phase, reviewed code & mentored the team.',
    'Stepped up to proactively fill integration gaps between the 3 systems, resulting in quicker defect resolution.',
    'Built the User, Checkout, CSR & E-procurement flows.'
  ],
  stack: 'Angular 10, Web Components, TypeScript, HTML, Sass, BEM, Jasmine, Karma, Material, NgRx, REST, AEM'
}, {
  name: 'Software and Subscription Download Site',
  role: 'Front End Web Developer and Lead',
  description: [
    'Created two platforms to facilitate software downloads, one for admins to maintain, version and scale software downloads and their availability, and second for end-users to easily download and use the corresponding software and resources.',
    'Leveraged Angular Material Tables for lists and pagination.',
    'Created a web component to facilitate software download from multiple sites throughout the domain.',
    'Used multipart download for larger files with progress tracking.'
  ],
  stack: 'Angular 8, Web Components, TypeScript, HTML, Sass, BEM, Jasmine, Karma, Material, NgRx, REST, AEM'
}, {
  name: 'User Centric Dashboard',
  role: 'Front End Web Developer',
  description: [
    'Created a wholistic dashboard for customers of a Life Sciences & Health Care Client',
    'Gathered and presented data from multiple source systems efficiently to represent meaningful content for the users',
    'Developed a calendar widget from scratch for scheduling events for IOT powered devices',
    'Personalized the dashboard according to any user\'s requirements and preferences'
  ],
  stack: 'Angular 10, TypeScript, HTML, Sass, BEM, Jasmine, Karma, Material, NgRx, REST, AEM'
}, {
  name: 'Referral Portal',
  role: 'Front End Web Developer and Lead',
  description: [
    'Delivered an employee handy dashboard for Referrals and internal Job search. It provides the solution to all referral problems not just for referees but for HRs as well.',
    'The users now can not only search for jobs but also track their referrals, reach out to respective HR team members and report troubles if any.'
  ],
  stack: 'Angular 6, Angular Material, SharePoint'
}, {
  name: 'Discussion Forum',
  role: 'Front End Web Developer and Lead',
  description: [
    'Delivered an internal discussion forum site. An SPA with distinguished news widgets with subscriptions, email notifications and analytics'
  ],
  stack: 'React.js, SharePoint'
}, {
  name: 'Life Sciences and Health Care E-Commerce Site',
  role: 'Front End Web Developer',
  description: [
    'Transformed an Out of the Box Commerce UI into a client specific UI in a server driven front end.',
    'This On-premises architecture had the backbone of the IBM WCS commerce engine. Brought in responsive web layouts and fluidity to the implementation.',
    'Took measures to improve Security and Performance.',
    'Postlaunch, provided 24/7 support and hyper-care'
  ],
  stack: 'JavaScript, jQuery, HTML, CSS, Bootstrap, REST'
}, {
  name: 'Health Care Salesforce Community Site',
  role: 'Front End Web Developer',
  description: [
    'Contributed to major UI corrections and consistency during critical project times prior to the launch of the HealthCare client site.'
  ],
  stack: 'Salesforce Community, jQuery'
}, {
  name: 'Networking Hardware E-Commerce Site',
  role: 'Front End Web Developer',
  description: [
    'Developed an E-Commerce product site for a project proposal to Networking Hardware E-Commerce Giant.'
  ],
  stack: 'AngularJS, AEM, IBM WCS'
}, {
  name: 'Design Club',
  role: 'Training Lead and Design Mentor',
  description: ['Delivered tool and design theory trainings to design focused groups.',
    'Designed print and web based designs. ',
    'Mentored the new club members in design and communication.'],
  stack: 'Adobe PhotoShop, Adobe Illustrator'
}]
export const Responsibilities = [
  'Deliverable Estimations',
  'Architecture Design',
  'Source System mappings',
  'Design reviews with UX team',
  'Business requirement analysis',
  'Team mentoring',
  'Responsive Web Development',
  'Cross browser Development',
  'Interaction Development',
  'Pixel-Perfect Development',
  'Single Page App Development',
  'Device testing',
  'Unit Testing',
  'Acceptance testing',
  'Front End Security',
  'Site Performance measures',
  'Code reviews',
  'Sprint Planning',
  'Sprint demo delivery',
  'Bug fixing',
  'Knowledge Transfer',
  'Environment management',
  'Git management',
  'Build management',
  'Front End Analytics',
  'Code enhancements',
  'Change requests',
  'Hyper-care ',
  'Support'
]