import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Route } from '../../models/route';
import { ReactComponent as Logo } from '../../../logo.svg';
import './Nav.scss';

const Routes: Route[] = [{
  link: '/home',
  title: 'Home',
  theme: 'dark'
}, {
  link: '/experience',
  title: 'Experience',
  theme: 'light'
}, {
  link: '/resume',
  title: 'Resume',
  theme: 'light'
}, {
  link: '/about',
  title: 'About',
  theme: 'light'
}]

export const Nav = () => {
  let location = useLocation();
  const [theme, setTheme] = useState<string>('dark');
  const [showMobileNav, setShowMobileNav] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [activeRoute, setActiveRoute] = useState<Route>();

  useEffect(
    () => {
      const active = Routes.find((route) => (route.link === location.pathname)) ?? Routes[0];
      setActiveRoute(active);
      setTheme(active.theme);
    }, [location]
  )
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
      setShowMobileNav(false);
    }
  }, []);

  const navigateEffect = () => {
    setShowMobileNav(!isMobile);
  }

  return (
    <>
      {isMobile && <div onClick={navigateEffect} className={`nav__back ${showMobileNav && theme === 'light' ? 'nav__back--visible' : ''}`}></div>}
      <section className={`nav__container ${theme === 'dark' ? 'nav__container--dark' : 'nav__container--light'}`}>
        <NavLink key={'Logo'} to={'/home'}>
          <Logo title="Logo" className={`nav__logo nav__logo--${theme}`} />
        </NavLink>
        <nav>
          {isMobile &&
            <div
              className={`nav__toggle ${showMobileNav ? 'nav__toggle--open' : 'nav__toggle--closed'}`}
              onClick={() => setShowMobileNav(!showMobileNav)}>
              {!showMobileNav && <span className="nav__active-route">{activeRoute && activeRoute.title !== Routes[0].title && activeRoute.title}</span>}
              <span>
                <div className="nav__toggle-bar nav__toggle-bar--one"></div>
                <div className="nav__toggle-bar nav__toggle-bar--two"></div>
                <div className="nav__toggle-bar nav__toggle-bar--three"></div>
              </span>
            </div>}
          {<section className={`nav__links ${isMobile && showMobileNav ? 'nav__links--open' : ''}`}>
            {Routes.map((route) =>
              <NavLink
                key={route.title}
                activeClassName="nav__link--active"
                className="nav__link"
                to={route.link}
                onClick={navigateEffect}
              >{route.title}</NavLink>)}
          </section>}
        </nav>
      </section>
    </>
  )
}