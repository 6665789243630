import './Resume.scss';

export const Resume = () => {
  return (
    <article className="resume__container">
      <object data="./KanuPriyaMiddha.pdf" type="application/pdf" width="100%" height="100%">
        <p className="resume__download"><a href="./KanuPriyaMiddha.pdf" download>Download my resume.</a></p>
      </object>
    </article>
  );
};
