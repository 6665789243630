import { Project } from '../../shared/models/project';
import { Projects, Responsibilities } from '../../data/experience';
import './Experience.scss';

export const Experience = () => {
  return (
    <article className="experience__container">
      <section className="experience__body">
        <section>
          <h2 className="experience__body-header">Here are some projects I've worked on professionally</h2>
          <div className="experience__projects">
            {Projects.map((project: Project, index) => <div className="experience__project" key={index}>
              <h3>{project.name}</h3>
              <p>Role: {project.role}</p>
              <ul>{project.description.map((desc, index) => <li key={index}>{desc}</li>)}</ul>
              <p className="experience__stack">{project.stack.split(',').map((s, i) => <span className="experience__stack-pill" key={i}>{s.trim()}</span>)}</p>
            </div>)}
          </div>

          <h2 className="experience__body-header">My Overall Responsibilities</h2>
          <section className="experience__responsibilities">
            {Responsibilities.map((resp, index) => <span key={index}>{resp}</span>)}
          </section>
        </section>
      </section>
    </article>
  );
};
